import { useState, useContext } from 'react'

import { Button, theme } from '@hhgtech/hhg-components'
import { PATHS } from 'api/paths'
import {
  callApiWithAuth,
  getMarryBabyUrl,
  getTogetherPath,
  redirectToSSOWithReturnUrl,
} from 'api/utils'
import { Text } from 'components/atoms/text'
import { CommunityApi } from 'interfaces/types'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { AppContext } from 'state/appContext'
import { CSSProperties } from 'styled-components'

import { StyledCommunityCardContainer } from './index.styled'

type Props = CommunityApi & {
  isMobile: boolean
  className?: string
  itemId?: string // DO NOT REMOVE, NEED FOR SCROLL LIST HORIZONTAL
}

const CommunityCard = ({
  title,
  cover_image,
  thumbnail,
  count_topics,
  count_posts,
  count_members,
  community_slug,
  community_id,
  joined: joinedProp,
  isMobile,
  className,
}: Props) => {
  const { formatMessage: f } = useIntl()
  const {
    state: { isLoggedIn },
  } = useContext(AppContext)

  const [joined, setJoined] = useState<boolean>(joinedProp)
  const [loading, setLoading] = useState<boolean>(false)
  const { locale = 'vi-VN' } = useRouter()

  const onButtonClick = async () => {
    if (isLoggedIn) {
      if (!joined) {
        try {
          setLoading(true)
          await callApiWithAuth(
            getTogetherPath(PATHS.POST_TOGETHER_JOIN_COMMUNITY),
            'POST',
            { data: { ids: [community_id] } },
          )
          setJoined(true)
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      } else {
        try {
          setLoading(true)
          await callApiWithAuth(
            getTogetherPath(PATHS.POST_TOGETHER_LEAVE_COMMUNITY),
            'POST',
            { data: { ids: [community_id] } },
          )
          setJoined(false)
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      }
    } else {
      const destination = `${
        typeof window !== 'undefined' ? window.location.origin : ''
      }/community/${community_slug}`
      redirectToSSOWithReturnUrl(destination)
    }
  }

  const href =
    typeof window !== 'undefined'
      ? window.location.origin.concat('/community/', community_slug)
      : `${getMarryBabyUrl(locale)}/community/${community_slug}`

  return (
    <StyledCommunityCardContainer
      className={className}
      style={
        {
          '--cover-image': `url(${
            cover_image || '/images/community_cover_fallback.png'
          })`,
          '--thumbnail': `url(${
            thumbnail || '/images/community_thumbnail_fallback.svg'
          })`,
        } as CSSProperties
      }
      href={href}
    >
      <div className="cover">
        <div className="thumbnail">
          <img
            loading="lazy"
            src={thumbnail || '/images/community_thumbnail_fallback.svg'}
          />
        </div>
      </div>

      <div className="content">
        <Text className="community-card__title" type="subtitle-1">
          {title}
        </Text>

        <div className="info">
          <Text
            type={isMobile ? 'caption-2' : 'bodytext-3'}
            color={theme.mbColors.midGray}
          >
            {String(count_topics).concat(' ', f({ id: 'community.topic' }))}
          </Text>
          <Text
            type={isMobile ? 'caption-2' : 'bodytext-3'}
            color={theme.mbColors.midGray}
          >
            {String(count_posts).concat(' ', f({ id: 'community.post' }))}
          </Text>
          <Text
            type={isMobile ? 'caption-2' : 'bodytext-3'}
            color={theme.mbColors.midGray}
          >
            {String(count_members).concat(' ', f({ id: 'community.member' }))}
          </Text>{' '}
        </div>

        <div
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <Button
            theme="marryBaby"
            isDisabled={loading}
            isLoading={loading}
            onClick={onButtonClick}
            size="md"
            color="primary"
            className={joined ? 'following' : undefined}
          >
            {joined ? (
              <>
                <img loading="lazy" src="/icons/done_line.svg" alt="" />
                <span>{f({ id: 'community.follow' })}</span>
              </>
            ) : (
              <>{f({ id: 'community.joinNow' })}</>
            )}
          </Button>
        </div>
      </div>
    </StyledCommunityCardContainer>
  )
}

export { CommunityCard }
