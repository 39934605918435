import { MediaQueries } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledCardWrapper = styled.a`
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;

  ${MediaQueries.mbUp} {
    max-width: 240px;
    height: 320px;
  }
  ${MediaQueries.mbDown} {
    height: 220px;
  } ;
`
export const StyledSeemoreButton = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`
