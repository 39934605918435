import { CSSProperties, useEffect, useState } from 'react'
import React from 'react'

import { formatCommunity } from 'api/dataTransform'
import { PATHS } from 'api/paths'
import { callApiWithOptionalAuth } from 'api/utils'
import { getTogetherPath } from 'api/utils'
import { CommunityApi } from 'interfaces/types'

import { CommunityCard } from '../homepageCommunity/CommunityCard'
import { ScrollList } from '../scrollList'
import { StyledCardListCommunity } from './index.styled'

export type Props = {
  className?: string
  style?: CSSProperties
  isMobile?: boolean
}

const CardListCommunity = ({ className, style, isMobile }: Props) => {
  const [communities, setCommunities] = useState<CommunityApi[]>([])
  const listColumn = isMobile ? 1 : 3
  const showNavigation =
    !isMobile && communities && communities.length > listColumn

  if (typeof window === 'undefined') {
    React.useLayoutEffect = React.useEffect
  }

  useEffect(() => {
    callApiWithOptionalAuth(
      getTogetherPath(PATHS.GET_TOGETHER_COMMUNITY_AUTH),
      getTogetherPath(PATHS.GET_TOGETHER_COMMUNITY),
      'GET',
    ).then((res) => {
      setCommunities(res?.data?.communities?.map(formatCommunity) || [])
    })
  }, [])

  return (
    <StyledCardListCommunity className={className} style={style}>
      <ScrollList
        hideNavigation={isMobile || !showNavigation}
        className="card-communities-wrapper"
      >
        {communities?.map((community, index) => {
          return (
            <CommunityCard
              key={index}
              itemId={String(index)}
              // community={community}
              className="card-community-item"
              isMobile={!!isMobile}
              {...community}
            />
          )
        })}
      </ScrollList>
    </StyledCardListCommunity>
  )
}

export { CardListCommunity }
