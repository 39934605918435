import styled from 'styled-components'

export const StyledScrollList = styled.div`
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .scroll-list-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .scroll-arrow {
    position: absolute;
    z-index: 15;
    top: 50%;
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 50%;
    box-shadow: 0px 8px 20px rgb(0 0 0 / 10%);
    cursor: pointer;
    transform: translateY(-50%);
    &:disabled {
      display: none;
    }
    .scroll-arrow-icon {
      width: 1.5rem;
    }
    &.scroll-left-arrow {
      left: 1rem;
      padding-right: 2px;
      .scroll-arrow-icon {
        transform: rotate(180deg);
      }
    }
    &.scroll-right-arrow {
      right: 1rem;
      padding-left: 2px;
    }
  }
`
