import { MediaQueries, ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledCommunityCardContainer = styled.a`
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;

  .cover {
    position: relative;
    padding-top: 62.5%;
    margin-bottom: 24px;
    background-image: var(--cover-image);
    background-size: cover;

    ${MediaQueries.mbDown} {
      height: 130px;
    }

    .thumbnail {
      position: absolute;
      bottom: 0px;
      left: 16px;
      overflow: hidden;
      width: 40px;
      height: 40px;
      border: none;
      background: white;
      border-radius: 8px;
      transform: translateY(50%);
      ${MediaQueries.mbDown} {
        width: 32px;
        height: 32px;
      }
    }
  }

  .content {
    padding: 4px 16px 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .community-card__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .info {
      display: flex;
      padding: 4px 0 8px;
      flex-wrap: wrap;
      flex: 1;
      > p:not(:first-child) {
        position: relative;
        margin-left: 18px;
        ${MediaQueries.mbDown} {
          margin-left: 10px;
        }
        &:before {
          position: absolute;
          top: 8px;
          left: -10px;
          display: block;
          width: 3px;
          height: 3px;
          background-color: ${(props: ThemeProps) =>
            props.theme.mbColors.midGray};
          border-radius: 50%;
          content: '';
          ${MediaQueries.mbDown} {
            left: -6px;
            top: 7px;
          }
        }
      }
    }

    button {
      width: 100%;
      &[data-color='secondary'][data-size='md'] {
        ${MediaQueries.mbUp} {
          padding: 10px 24px;
        }
        ${MediaQueries.mbDown} {
          padding: 6px 24px;
        }
      }

      &.following {
        border-color: ${(props: ThemeProps) => props.theme.mbColors.lightMint};
        background-color: ${(props: ThemeProps) =>
          props.theme.mbColors.lightMint};
        color: ${(props: ThemeProps) => props.theme.mbColors.mint};

        &:hover {
          color: ${(props: ThemeProps) => props.theme.mbColors.pink};
        }

        > span {
          display: flex;
          justify-content: center;
        }

        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }
  }
`

export const StyledCardPostContent = styled.div`
  padding: 0.5rem 1rem 1rem;
  font-size: 14px;
  line-height: 24px;
  word-break: break-word;
  .post-content-replying {
    margin-right: 5px;
    color: ${(props: ThemeProps) => props.theme.mbColors.pink};
    cursor: pointer;
  }
  .post-content {
    display: inline;
    white-space: pre-wrap;
    > *:first-child {
      &:not(ul),
      &:not(ol) {
        display: inline-block;
      }
    }
    &.has-see-more {
      *:last-child {
        display: inline;
      }
    }
    img {
      width: 100%;
      height: unset;
    }
    ul,
    ol {
      list-style-position: inside;
      padding-left: 1rem;
      display: block;
    }
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style-type: disc;
    }
    mention {
      color: ${(props: ThemeProps) => props.theme.mbColors.pink};
      :hover {
        text-decoration: underline;
      }
    }
  }
  .see-more-text {
    color: ${(props: ThemeProps) => props.theme.mbColors.pink};
    cursor: pointer;
  }
`
