import { MediaQueries } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledCardListCommunity = styled.div`
  position: relative;
  width: 100%;
  padding: 0 1rem;
  ${MediaQueries.mbDown} {
    .heading {
      padding: 0 16px;
    }
  }

  .card-communities-wrapper {
    position: initial;
    display: flex;
    width: 100%;
    overflow-y: hidden;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    a,
    img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
    .react-horizontal-scrolling-menu--wrapper {
      width: 100%;
    }
    .react-horizontal-scrolling-menu--scroll-container {
      display: flex;
      width: 100%;
      padding-right: 2px;
      padding-left: 2px;
      .react-horizontal-scrolling-menu--item {
        --card-gap: 1.5rem;
        --card-width: max(calc((100% - (var(--card-gap) * 2)) / 3), 215px);
        width: calc(var(--card-width) + var(--card-gap));
        flex-shrink: 0;
        padding: 0 0.5rem 3px;
        padding-right: 0;
        padding-left: var(--card-gap);
        ${MediaQueries.mbDown} {
          --card-width: max(80%, 215px);
          --card-gap: 1rem;
        }
        &:first-child {
          width: var(--card-width);
          padding-left: 0;
        }
        > .card-community-item {
          width: 100%;
          min-width: unset;
          height: 100%;
        }
      }
    }
    .scroll-arrow {
      &.scroll-left-arrow {
        left: 2rem;
      }
      &.scroll-right-arrow {
        right: 2rem;
      }
    }
  }
`
