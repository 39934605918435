import { SpotlightSummary } from 'interfaces/types'

export const checkAvaiableSpotlight = (spotlight: SpotlightSummary) => {
  const today = new Date()
  if (spotlight?.startDate) {
    const startDate = new Date(spotlight.startDate)
    if (today.getTime() < startDate.getTime()) return false
  }
  if (spotlight?.endDate) {
    const endDate = new Date(spotlight.endDate)
    if (today.getTime() > endDate.getTime()) return false
  }
  return true
}
