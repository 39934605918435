import { StyledIconContainer } from 'components/molecules/iconContainer'
import { domainLocales } from 'domainLocales'
import { SpotlightSummary } from 'interfaces/types'
import { useRouter } from 'next/router'

import { StyledCardWrapper, StyledSeemoreButton } from './index.styled'
export type Props = {
  spotlight: SpotlightSummary
  isMobile?: boolean
  showSeeMore?: boolean
}
const SpotlightCard = ({ spotlight, showSeeMore = false, isMobile }: Props) => {
  const { locale = 'vi-VN' } = useRouter()

  return (
    <StyledCardWrapper
      href={spotlight.permalink}
      style={{
        backgroundImage: `url("${
          (isMobile ? spotlight.image : spotlight.desktopBanner) ||
          spotlight.image
        }")`,
      }}
      data-event-category="Spotlight"
      data-event-action="Click 1"
      data-event-label={`https://${domainLocales[locale]}${spotlight.permalink}`}
    >
      {showSeeMore && (
        <StyledSeemoreButton>
          <StyledIconContainer size={40} iconSize={24}>
            <img
              loading="lazy"
              src="/icons/arrow-right_line-dark.svg"
              alt="Detail"
            />
          </StyledIconContainer>
        </StyledSeemoreButton>
      )}
    </StyledCardWrapper>
  )
}

export { SpotlightCard }
