import {
  ContextType,
  MouseEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'

import { StyledScrollList } from './index.styled'

export default function useDrag() {
  const [clicked, setClicked] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [position, setPosition] = useState(0)
  const [diff, setDiff] = useState(0)

  const dragStart = useCallback((ev: MouseEvent) => {
    setPosition(ev.clientX)
    setDiff(0)
    setClicked(true)
  }, [])

  const dragStop = useCallback(
    () =>
      window.requestAnimationFrame(() => {
        setDragging(false)
        setClicked(false)
      }),
    [],
  )

  const dragMove = useCallback(
    (ev: MouseEvent, cb: (newPos: number) => void) => {
      const newDiff = position - ev.clientX

      const movedEnough = Math.abs(newDiff) > 5

      if (clicked && movedEnough) {
        setDragging(true)
      }

      if (dragging && movedEnough) {
        setPosition(ev.clientX)
        setDiff(newDiff)
        cb(newDiff)
      }
    },
    [clicked, dragging, position],
  )

  return {
    dragStart,
    dragStop,
    dragMove,
    diff,
    dragging,
    position,
    setDragging,
    setDiff,
    setPosition,
  }
}

type scrollVisibilityApiType = ContextType<typeof VisibilityContext>

export type Props = {
  className?: string
  hideNavigation?: boolean
  children?: ReactElement[]
}

export const ScrollList = ({
  className,
  hideNavigation,
  children = [],
}: Props) => {
  const { dragStart, dragStop, dragMove /* , dragging */ } = useDrag()
  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: MouseEvent,
  ) =>
    dragMove(ev, (newPos) => {
      if (scrollContainer.current) {
        const currentScroll = scrollContainer.current.scrollLeft
        scrollContainer.current.scrollLeft = currentScroll + newPos
      }
    })
  return (
    <StyledScrollList className={className} onMouseLeave={dragStop}>
      <ScrollMenu
        LeftArrow={!hideNavigation ? LeftArrow : undefined}
        RightArrow={!hideNavigation ? RightArrow : undefined}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        scrollContainerClassName="scroll-list-container"
      >
        {children}
      </ScrollMenu>
    </StyledScrollList>
  )
}

export function LeftArrow() {
  const {
    getPrevItem,
    isFirstItemVisible,
    scrollToItem,
    visibleItemsWithoutSeparators,
  } = useContext(VisibilityContext)

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isFirstItemVisible,
  )
  useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible)
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators])

  const clickHandler = () => {
    const prevItem = getPrevItem()
    scrollToItem(prevItem?.entry?.target, 'smooth', 'start')
  }

  return (
    <button
      className="scroll-arrow scroll-left-arrow"
      disabled={disabled}
      onClick={clickHandler}
    >
      <img
        loading="lazy"
        className="scroll-arrow-icon"
        src={'/images/chevronRight.svg'}
      />
    </button>
  )
}

export function RightArrow() {
  const {
    getNextItem,
    isLastItemVisible,
    scrollToItem,
    visibleItemsWithoutSeparators,
  } = useContext(VisibilityContext)

  const [disabled, setDisabled] = useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  )
  useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible)
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators])

  const clickHandler = () => {
    const nextItem = getNextItem()
    scrollToItem(nextItem?.entry?.target, 'smooth', 'end')
  }

  return (
    <button
      className="scroll-arrow scroll-right-arrow"
      disabled={disabled}
      onClick={clickHandler}
    >
      <img
        loading="lazy"
        className="scroll-arrow-icon"
        src={'/images/chevronRight.svg'}
      />
    </button>
  )
}
